<template>
  <div>
    <b-row>
      <b-col md="12">
        <label for="credorName">Credor</label>
        <v-select
          id="credorName"
          v-model="credor.selected"
          multiple
          label="title"
          :options="credor.credOption"
        />
      </b-col>
      <b-col
        md="6"
        class="mt-2"
      >
        <div v-if="tipo_base.selected =='acordo' ">
          <label>Período Data</label>
        </div>
        <div v-else>
          <label>Período Vencimento</label>
        </div>
        <b-form-group>
          <b-form-radio-group
            v-model="tipo_base.selected"
            :options="tipo_base.options"
            text-field="text"
            value-field="value"
            disabled-field="disabled"
          />
        </b-form-group>
        <b-row>
          <b-col cols="6">
            <b-form-input
              id="vencimento-inicio"
              v-model="vencimento.inicio"
              locale="pt"
              type="date"
            />
          </b-col>
          <b-col cols="6">
            <b-form-input
              id="vencimento-final"
              v-model="vencimento.final"
              locale="pt"
              type="date"
            />
          </b-col>
        </b-row>
      </b-col>

      <b-col
        lg="6"
        class="mt-2"
      >
        <b-row align-h="between">
          <b-col>
            <label>Status</label>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <b-form-checkbox
              v-model="statusOp.exceto"
              value="1"
              unchecked-value="0"
              inline
              class="custom-control-primary"
            >
              Exceto
            </b-form-checkbox>
          </b-col>
        </b-row>
        <v-select
          v-model="statusOp.selected"
          label="title"
          :options="statusOp.options"
        />
      </b-col>
      <b-col
        lg="6"
        class="mt-2"
      >
        <b-row class="justify-content-between">
          <b-col>
            <label>Evento</label>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <b-form-checkbox
              v-model="eventos.exceto"
              value="1"
              inline
              class="custom-control-primary"
            >
              Exceto
            </b-form-checkbox>
          </b-col>
        </b-row>
        <v-select
          v-model="eventos.selected"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          multiple
          label="title"
          :options="eventos.options"
        />
      </b-col>
      <b-col
        lg="6"
        class="mt-2"
      >
        <b-row class="justify-content-between">
          <b-col>
            <label>Motivo Pendência</label>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <b-form-checkbox
              v-model="mtPend.exceto"
              value="1"
              inline
              unchecked-value="0"
              class="custom-control-primary"
            >
              Exceto
            </b-form-checkbox>
          </b-col>
        </b-row>
        <v-select
          v-model="mtPend.selected"
          multiple
          label="title"
          :options="mtPend.options"
        />
      </b-col>
      <b-col
        lg="6"
        class="mt-2"
      >
        <b-row align-h="between">
          <b-col>
            <label>Classificação Negativação</label>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <b-form-checkbox
              v-model="classNeg.exceto"
              value="1"
              unchecked-value="0"
              inline
              class="custom-control-primary"
            >
              Exceto
            </b-form-checkbox>
          </b-col>
        </b-row>
        <v-select
          v-model="classNeg.selected"
          label="title"
          multiple
          :options="classNeg.options"
        />
      </b-col>
      <b-col
        lg="6"
        class="mt-2"
      >
        <b-row align-h="between">
          <b-col>
            <label>Classificação Cliente</label>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <b-form-checkbox
              v-model="classCliente.exceto"
              value="1"
              inline
              unchecked-value="0"
              class="custom-control-primary"
            >
              Exceto
            </b-form-checkbox>
          </b-col>
        </b-row>
        <v-select
          v-model="classCliente.selected"
          label="title"
          multiple
          :options="classCliente.options"
        />
      </b-col>
      <b-col
        lg="6"
        class="mt-2"
      >
        <b-row align-h="between">
          <b-col>
            <label>Localização Cliente</label>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <b-form-checkbox
              v-model="localizaCliente.exceto"
              value="1"
              inline
              unchecked-value="0"
              class="custom-control-primary"
            >
              Exceto
            </b-form-checkbox>
          </b-col>
        </b-row>
        <v-select
          v-model="localizaCliente.selected"
          label="title"
          multiple
          :options="localizaCliente.options"
        />
      </b-col>
      <b-col
        lg="12"
        class="mt-2"
      >
        <b-row>
          <b-col
            md="3"
            class="mt-2"
          >
            <div v-if="valor.select ==1 ">
              <label>Valor R$</label>
              <b-row>
                <b-col lg="6">
                  <b-form-input
                    id="valor-inicio"
                    v-model="valor.inicial"
                    type="number"
                    placeholder="Inicial"
                    min="1"
                  />
                </b-col>
                <b-col lg="6">
                  <b-form-input
                    id="valor-final"
                    v-model="valor.final"
                    type="number"
                    placeholder="Final"
                    min="1"
                  />
                </b-col>
              </b-row>
            </div>
            <div v-else>
              <label>Quantidade</label>
              <b-row>
                <b-col lg="8">
                  <b-form-input
                    id="valor-inicio"
                    v-model="valor.quantOp"
                    type="number"
                    placeholder="Quant. Op"
                  />
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col
            lg="4"
            md="4"
            sm="6"
            class="mt-2"
          >
            <b-form-group>
              <b-form-radio-group
                v-model="valor.select"
                name="radio-validation"
                :options="valor.options"
                value-field="value"
                text-field="text"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="5"
            class="mt-2"
          >
            <b-row>
              <b-col>
                <label>Período de contato</label>
              </b-col>
              <b-col>
                <b-form-checkbox
                  v-model="periodo_contato.exceto"
                  value="1"
                  inline
                  unchecked-value="0"
                  class="custom-control-primary"
                >
                  Exceto
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-input-group>
                  <flat-pickr
                    v-model="periodo_contato.contato_inicio"
                    locale="pt"
                    placeholder="Inicial"
                    class="form-control"
                    :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                  />
                </b-input-group>
              </b-col>
              <b-col md="6">
                <b-input-group>
                  <flat-pickr
                    v-model="periodo_contato.contato_final"
                    locale="pt"
                    placeholder="Final"
                    class="form-control"
                    :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                  />
                </b-input-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            md="12"
            class="mt-3"
          >
            <b-row class="d-flex flex-row-reverse">
              <b-button
                variant="danger"
                class="ml-2 mr-2"
              >
                Cancelar
              </b-button>
              <b-button
                variant="primary"
                @click="filtroClientes"
              >
                Filtrar
              </b-button>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormRadioGroup,
  BButton,
  VBModal,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import axios from '../../../../../axios-auth'

export default {
  components: {
    vSelect,
    BFormCheckbox,
    BRow,
    BButton,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormRadioGroup,
    flatPickr,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      select: [],
      localizacao: [],
      colorCache: {},
      cep: null,
      numero: null,
      rua: null,
      bairro: null,
      cidade: null,
      ativo: null,
      nome: '',
      razaoSocial: '',
      name: '',
      cpfCnpj: '',
      email: '',
      nameState: null,
      submittedNames: [],
      selected: { title: '' },
      option: [{ title: 'Ativo' }, { title: 'Inativo' }],
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      filteredItems: null,
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      credor: {
        credOption: [],
        selected: [],
        ids: [],
      },
      vencimento: {
        inicio: '',
        final: '',
      },
      statusOp: {
        selected: '',
        options: [],
        ids: [],
      },
      eventos: {
        selected: '',
        options: [],
        ids: [],
        exceto: '0',
      },
      mtPend: {
        selected: '',
        options: [],
        ids: [],
        exceto: '0',
      },
      classNeg: {
        selected: '',
        options: [],
        ids: [],
        exceto: '0',
      },
      classCliente: {
        options: [],
        ids: [],
        selected: '',
        exceto: '0',
      },
      localizaCliente: {
        options: [
          { title: 'Localizado', value: 'Localizado' },
          { title: 'Não Localizado', value: 'Nao Localizado' },
          { title: 'Em Processo de Localizacão', value: 'Em Processo de Localizacao' },
        ],
        title: [],
        selected: '',
        exceto: '0',
      },
      tipo_base: {
        selected: 'operacao',
        options: [
          { text: 'Operação', value: 'operacao' },
          { text: 'Acordo', value: 'acordo' },
          { text: 'Parcela', value: 'parcela' },
        ],
      },
      valor: {
        options: [
          { text: 'Valor Médio Cliente', value: 1 },
          { text: 'Operação Cliente', value: 2 },
        ],
        select: 1,
        inicial: '',
        final: '',
        quantOp: '',
        avaible: false,
      },
      periodo_contato: {
        contato_inicio: '',
        contato_final: '',
        exceto: 0,
      },
      form: {
        cardNumber: null,
        date: null,
        phone: null,
      },
      mask: {
        cep: {
          delimiters: ['-'],
          blocks: [5, 3],
        },
        cpfCnpj: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          uppercase: true,
        },
        telefone: {
          delimiters: [' ', '-', '-'],
          blocks: [2, 4, 4],
          uppercase: true,
        },
      },
      fields: [
        'check',
        { key: 'id', label: 'Id' },
        { key: 'cpf_cnpj', label: 'CNPJ/CPF', sortable: true },
        { key: 'cliente', label: 'cliente' },
        // { key: 'nome', label: 'Nome', sortable: true },
        { key: 'localizacao', label: 'localização' },
        { key: 'status_cliente_id', label: 'classificação' },
        // { key: 'actions', label: 'ver' },
      ],
      items: [],
      itemsStorage: [],
      dbFilter: '',
      status: [{
        1: 'Current', 2: 'Ativo', 3: 'Rejected', 4: 'Inativo', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      plataFormaSelected: '',
    }
  },
  async created() {
    this.permissions = JSON.parse(localStorage.permissoes).clientes
    // await this.updateList()

    await Promise.all([
    // CREDORES
      axios.get('api/v1/credores/', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          const objeto = res.data.dados
          // eslint-disable-next-line array-callback-return
          objeto.map(dt => {
            this.credor.credOption.push({ title: dt.nome, id: dt.id })
            this.credor.credOption.sort((a, b) => {
              const nameA = a.title.toLowerCase()
              const nameB = b.title.toLowerCase()
              if (nameA < nameB) return -1
              if (nameA > nameB) return 1
              return 0
            })
          })
        }),

      // STATUS OPERAÇÃO
      axios.get('api/v1/info/statusoperacao', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(dt => (
          this.statusOp.options.push({
            title: dt.nome,
            id: dt.id,
          })
        ))
      }),

      // EVENTO
      axios.get('api/v1/motivo_evento', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(dt => (
          this.eventos.options.push({
            title: dt.nome,
            id: dt.id,
          })
        ))
      }),

      // MOTIVO PENDENCIA
      axios.get('api/v1/info/motivopendencia', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(dt => (
          this.mtPend.options.push({
            title: dt.nome,
            id: dt.id,
          })
        ))
      }),

      // CLASSIFICAÇÃO NEGATIVAÇÃO
      axios.get('api/v1/info/classificacaonegativacao', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(dt => (
          this.classNeg.options.push({
            title: dt.nome,
            id: dt.id,
          })
        ))
      }),

      // CLASSIFICAÇÃO Cliente
      axios.get('api/v1/info/classificacaocliente', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(dt => (
          this.classCliente.options.push({
            title: dt.nome,
            id: dt.id,
          })
        ))
      }),
      axios.get('api/v1/status_localizacao', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
      // eslint-disable-next-line array-callback-return
        res.data.dados.dados.map(dt => {
          this.localizacao.push({
            label: dt.nome,
            id: dt.id,
          })
        })
      }),
    ])
  },
  methods: {
    filtroClientes() {
      let body = {
        // pag: 1,
        // paginate: 15,
        filtros: {
        },
      }
      if (this.periodo_contato.contato_inicio && this.periodo_contato.contato_final) {
        body = {
          filtros: {
            // pag: 1,
            // paginate: 15,
            periodo_contato: {},
          },
        }
      }
      // VALIDACAO CREDOR
      if (this.credor.selected.length) {
        this.credor.ids = []
        // eslint-disable-next-line array-callback-return
        this.credor.selected.map(item => {
          this.credor.ids.push(item.id)
        })
        body.filtros.credores = this.credor.ids
      }

      // TIPO BASE
      if (this.tipo_base.selected) {
        body.filtros.tipo_base = this.tipo_base.selected
      }

      // Valor Médio Cliente
      if (this.valor.select) {
        body.filtros.valor_medio_cliente = {
          valor_inicial: this.valor.inicial,
          valor_final: this.valor.final,
        }
      }

      // Quantidade de Operações
      if (this.valor.quantOp) {
        body.filtros.operacao_por_cliente = {
          valor: this.valor.quantOp,
        }
      }

      // VALIDACAO VENCIMENTO
      if (this.vencimento.inicio) {
        body.filtros.data_inicial = this.vencimento.inicio
      }

      if (this.vencimento.final) {
        body.filtros.data_final = this.vencimento.final
      }

      // VALIDACAO STATUS
      if (this.statusOp.selected) {
        body.filtros.status_operacao = {
          valor: [this.statusOp.selected.id],
          // eslint-disable-next-line radix
          exceto: parseInt(this.statusOp.exceto),
        }
      }

      // VALIDACAO EVENTOS
      if (this.eventos.selected) {
        this.eventos.ids = []
        // eslint-disable-next-line array-callback-return
        this.eventos.selected.map(item => {
          this.eventos.ids.push(item.id)
        })

        body.filtros.motivo_evento = {
          valor: this.eventos.ids,
          // eslint-disable-next-line radix
          exceto: parseInt(this.eventos.exceto),
        }
      }
      // VALIDACAO PENDENCIA
      if (this.mtPend.selected) {
        this.mtPend.ids = []
        // eslint-disable-next-line array-callback-return
        this.mtPend.selected.map(item => {
          this.mtPend.ids.push(item.id)
        })

        body.filtros.motivo_pendencia = {
          valor: this.mtPend.ids,
          // eslint-disable-next-line radix
          exceto: parseInt(this.mtPend.exceto),
        }
      }

      // PERIODO CONTATO
      if (this.periodo_contato.contato_inicio) {
        body.filtros.periodo_contato.data_inicial = this.periodo_contato.contato_inicio
        // .concat(' 00:00:00')
        body.filtros.periodo_contato.exceto = parseFloat(this.periodo_contato.exceto)
      }

      if (this.periodo_contato.contato_final) {
        body.filtros.periodo_contato.data_final = this.periodo_contato.contato_final
        // .concat(' 23:59:59')
        body.filtros.periodo_contato.exceto = parseFloat(this.periodo_contato.exceto)
      }

      // VALIDCAO CLASSIFICACAO NEGATIVACAO
      if (this.classNeg.selected) {
        this.classNeg.ids = []
        // eslint-disable-next-line array-callback-return
        this.classNeg.selected.map(item => {
          this.classNeg.ids.push(item.id)
        })
        body.filtros.classificacao_negativacao = {
          valor: this.classNeg.ids,
          // eslint-disable-next-line radix
          exceto: parseInt(this.classNeg.exceto),
        }
      }

      // VALIDACAO CLASSIFICAÇÃO CLIENTE
      if (this.classCliente.selected.length) {
        this.classCliente.ids = []
        this.classCliente.selected.map(item => {
          this.classCliente.ids.push(item.id)
        })
        body.filtros.classificacao_cliente = {
          valor: this.classCliente.ids,
          exceto: parseInt(this.classCliente.exceto),
        }
      }

      // LOCALIZAÇÂO CLIENTE
      if (this.localizaCliente.selected) {
        this.localizaCliente.title = []
        this.localizaCliente.selected.map(item => {
          this.localizaCliente.title.push(item.value)
        })
        body.filtros.localizacao = {
          valor: this.localizaCliente.title,
          exceto: parseInt(this.localizaCliente.exceto),
        }
      }

      if (Object.keys(body.filtros).length <= 2) {
        this.$swal({
          title: 'Error!',
          text: 'Selecione pelo menos 1 filtro ',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        this.$emit('body-filtro', body)
      }
    },
  },
}
</script>
