<template>
  <div>
    <div v-if="permissions['clientes.index'].permissao">
      <!-- button -->
      <div>
        <b-row
          align-h="between"
          class="mb-2"
        >
          <b-col cols="auto">
            <b-row align-h="start">
              <b-col
                cols="auto"
                class="my-1"
              >
                <b-button
                  v-b-modal.modal-filtro
                  variant="warning"
                >
                  Filtro
                </b-button>
              </b-col>
              <b-col
                cols="auto"
                class="my-1"
              >
                <b-button
                  v-b-modal.reset-filtro
                  variant="warning"
                  @click="resetFiltro()"
                >
                  Limpar Filtro
                </b-button>
              </b-col>
              <b-col
                cols="auto"
                class="my-1"
              >
                <b-button
                  v-if="permissions['clientes.store'].permissao"
                  id="toggle-btn"
                  v-b-modal.modal-prevent-closing
                  variant="primary"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Novo Cliente</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            class="d-flex justify-content-end"
          >
            <b-row align-h="end">
              <b-col
                cols="auto"
                class="my-1"
              >
                <b-button
                  variant="outline-warning"
                  @click="exportFile"
                >
                  <feather-icon
                    icon="FileTextIcon"
                  />
                  Exportar excel
                </b-button>
              </b-col>
              <b-col
                cols="auto"
                class="my-1"
              >
                <b-button
                  v-if="permissions['clientes.updateLoteLocalizacaoCliente'].permissao"
                  v-b-modal.modalEnriquecimento
                  variant="outline-warning"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  Enriquecimento de dados
                </b-button>
              </b-col>
              <b-col
                cols="auto"
                class="my-1"
              >
                <b-button
                  v-if="permissions['clientes.updateLoteLocalizacaoCliente'].permissao"
                  v-b-modal.modalStatus
                  variant="outline-warning"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  Localização do Cliente
                </b-button>
              </b-col>
              <b-col
                cols="auto"
                class="my-1"
              >
                <b-button
                  v-if="permissions['clientes.updateLoteStatusClientes'].permissao"
                  v-b-modal.modalLocalizacao
                  variant="outline-warning"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  Classificação do Cliente
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-modal
          id="modalStatus"
          title="Status do Cliente"
          ok-only
          no-close-on-backdrop
          ok-title="Salvar"
          @show="resetModal"
          @ok="updateStatus"
        >
          <v-select
            v-model="localizacaoCliente"
            :options="optionsLocalizacao"
          />
        </b-modal>
        <!-- modal -->
        <b-modal
          id="modal-prevent-closing"
          ref="my-modal"
          title="Cadastrar Novo Cliente"
          ok-title="Cadastrar"
          cancel-title="Cancelar"
          cancel-variant="outline-secondary"
          @show="resetModal"
          @hidden="resetModal"
          @ok="handleOk"
        >
          <form
            ref="form"
            @submit.stop.prevent="handleSubmit"
          >
            <b-form>
              <b-form-group
                :state="nameState"
                label="Nome"
                invalid-feedback="Nome é o brigatório"
              >
                <b-form-input
                  v-model="name"
                  class="text-uppercase"
                  placeholder="Nome"
                  :state="nameState"
                  required
                />
              </b-form-group>
              <b-form-group
                label="CNPF/CNPJ"
              >
                <cleave
                  id="customDelimiter"
                  v-model="cpfCnpj"
                  class="form-control"
                  :raw="false"
                  :options="mask.cpfCnpj"
                  placeholder="000.000.000-00"
                />
              </b-form-group>
              <b-form-group
                label="CEP"
              >
                <b-input-group>
                  <cleave
                    v-model="cep"
                    name="cep"
                    class="form-control"
                    :raw="false"
                    :options="mask.cep"
                    placeholder="00000-000"
                    @keyup.enter="consultaCEP"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click="consultaCEP"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label="Rua"
              >
                <b-form-input
                  v-model="rua"
                  type="text"
                  placeholder="rua"
                />
              </b-form-group>
              <b-form-group
                label="Número"
              >
                <b-form-input
                  id="numero"
                  v-model="numero"
                  type="text"
                  placeholder="número"
                />
              </b-form-group>
              <b-form-group
                label="Bairro"
              >
                <b-form-input
                  id="bairro"
                  v-model="bairro"
                  type="text"
                  placeholder="bairro"
                />
              </b-form-group>
              <b-form-group
                label="Cidade"
              >
                <b-form-input
                  id="cidade"
                  v-model="cidade"
                  type="text"
                  placeholder="cidade"
                />
              </b-form-group>
            </b-form></form>
        </b-modal>
        <b-modal
          id="modal-filtro"
          title="Filtros"
          size="lg"
          ok-title="Filtrar"
          hide-footer
        >
          <filtroClients @body-filtro="filtroClientes" />
        </b-modal>
        <b-card>
          <!-- <div v-if="load">
            <b-row align-h="center">
              <b-col
                cols="auto"
                claas="d-flex"
              >
                <b-spinner style="width: 10rem; height: 10rem;" />
              </b-col>
            </b-row>
          </div> -->
          <b-overlay
            :show="load"
            variant="transparent"
          >
            <b-row
              align-h="between"
              class="my-1"
            >
              <b-col
                cols="2"
                class="mb-1"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-col>
              <b-col
                class="d-flex justify-content-end"
                lg="8"
                sm="12"
              >
                <b-form-input
                  id="nomeInput"
                  v-model="dbFilter"
                  size="sm"
                  class="text-uppercase"
                  type="search"
                  placeholder="Pesquisar"
                />
              </b-col>
            </b-row>
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="dbFilter"
              @filtered="onFiltered"
            >
              <template #head(check)>
                <b-form-checkbox
                  v-model="selectAll"
                />
              </template>
              <template #cell(status_cliente_id)="data">
                <div>
                  <!-- <feather-icon
                    icon="CircleIcon"
                    size="8"
                  /> -->
                  <b-badge
                    v-if="data.item.status_cliente_id"
                    :variant="randomBadge()"
                  >
                    {{ JSON.parse(data.item.status_cliente_id).title }}
                  </b-badge>
                </div>
              </template>
              <template #cell(check)="data">
                <b-form-checkbox
                  :key="data.cpf_cnpj"
                  v-model="select"
                  :value="data.item"
                />
              </template>
              <template #cell(cliente)="data">
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      size="32"
                      :text="getInitials(data.item.nome)"
                      :style="{backgroundColor: randomColor(data.item.id)}"
                      :to="{ name: 'cliente-view', params: {id: data.item.id }}"
                    />
                  </template>
                  <b-link
                    :to="{ name: 'cliente-view', params: {id: data.item.id }}"
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ data.item.nome }}
                  </b-link>
                </b-media>
              </template>
              <template #cell(cpf_cnpj)="data">
                {{ cpfCNPJ(data.item.cpf_cnpj) }}
              </template>

            <!-- <template #cell(status)="data">
                <b-badge :variant="status[1][data.value]">
                  {{ status[0][data.value] }}
                </b-badge>
              </template> -->
            <!-- Column: Actions -->
            <!-- <template #cell(actions)="data">

                <div class="text-nowrap">
                  <feather-icon
                    :id="`invoice-row-${data.item.id}-send-icon`"
                    icon="EyeIcon"
                    class="cursor-pointer"
                    size="16"
                    @click="$router.push({ name: 'cliente-view', params: { id: data.item.id }})"
                  />
                </div>
              </template> -->
            </b-table>

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
            />
          </b-overlay>
        </b-card>
        <b-modal
          id="modalLocalizacao"
          no-close-on-backdrop
          title="Classificação do Cliente"
          cancel-title="Cancelar"
          cancel-variant="danger"
          @show="resetModal"
          @ok="updateLocalizacao"
        >
          <v-select
            v-model="novaLocalizacao"
            label="title"
            multiple
            :options="classCliente.options"
          />
        </b-modal>
        <b-modal
          id="modalEnriquecimento"
          ref="modalEnriquecimento"
          title="Escolha a plataforma"
          ok-title="Enriquecer"
          ok-variant="success"
          cancel-variant="danger"
          cancel-title="Cancelar"
          @ok="setEnriquecimento"
        >
          <b-card>
            <b-row align-h="around">
              <b-col cols="4">
                <b-button
                  :variant="variantPlataforma('TARGET')"
                  @click="setPlataforma('TARGET')"
                >
                  TARGET DATA
                </b-button>
              </b-col>
              <b-col cols="4">
                <b-button
                  :variant="variantPlataforma('SHIFT')"
                  @click="setPlataforma('SHIFT')"
                >
                  SHIFT DATA
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-modal>
      </div>
    </div>
    <div v-else>
      <not-authorized />
    </div>
  </div>

</template>

<script>
import {
  BFormCheckbox,
  BTable,
  BAvatar,
  BMedia,
  BLink,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BOverlay,
  BModal, VBModal, BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import { saveAs } from 'file-saver'
import notAuthorized from '@/views/misc/notAuthorized.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import filtroClients from './components/filtroClients.vue'
import axios from '../../../../axios-auth'

const ExcelJS = require('exceljs')

export default {
  components: {
    vSelect,
    filtroClients,
    BFormCheckbox,
    BTable,
    BAvatar,
    BMedia,
    BLink,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BModal,
    Cleave,
    BForm,
    BOverlay,
    notAuthorized,
    FeatherIcon,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      permissions: null,

      load: true,
      localizacaoCliente: '',
      optionsLocalizacao: [
        { label: 'LOCALIZADO', value: 'Localizado' },
        { label: 'NAO LOCALIZADO', value: 'Nao Localizado' },
        { label: 'EM PROCESSO DE LOCALIZACAO', value: 'Em Processo de Localizacao' },
      ],
      novaLocalizacao: [],
      select: [],
      valor_enr: [],
      selectAll: false,
      localizacao: [],
      colorCache: {},
      cep: null,
      numero: null,
      rua: null,
      bairro: null,
      cidade: null,
      ativo: null,
      nome: '',
      razaoSocial: '',
      name: '',
      cpfCnpj: '',
      email: '',
      nameState: null,
      submittedNames: [],
      selected: { title: '' },
      option: [{ title: 'Ativo' }, { title: 'Inativo' }],
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      filteredItems: null,
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      vencimento: {
        inicio: '',
        final: '',
      },
      classCliente: {
        options: [],
        ids: [],
        selected: '',
        exceto: '0',
      },
      valor: {
        options: [
          { text: 'Valor Médio Cliente', value: 1 },
          { text: 'Operação Cliente', value: 2 },
        ],
        select: 1,
        inicial: '',
        final: '',
        quantOp: '',
        avaible: false,
      },
      form: {
        cardNumber: null,
        date: null,
        phone: null,
      },
      mask: {
        cep: {
          delimiters: ['-'],
          blocks: [5, 3],
        },
        cpfCnpj: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          uppercase: true,
        },
        telefone: {
          delimiters: [' ', '-', '-'],
          blocks: [2, 4, 4],
          uppercase: true,
        },
      },
      fields: [
        'check',
        { key: 'id', label: 'Id' },
        { key: 'cpf_cnpj', label: 'CNPJ/CPF', sortable: true },
        { key: 'cliente', label: 'cliente' },
        // { key: 'nome', label: 'Nome', sortable: true },
        { key: 'localizacao', label: 'localização' },
        { key: 'status_cliente_id', label: 'classificação' },
        // { key: 'actions', label: 'ver' },
      ],
      items: [],
      itemsStorage: [],
      dbFilter: '',
      status: [{
        1: 'Current', 2: 'Ativo', 3: 'Rejected', 4: 'Inativo', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      plataFormaSelected: '',
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    itemsStorage: {
      handler() {
        localStorage.setItem('itemsStorage', JSON.stringify(this.itemsStorage))
      },
    },
    selectAll(n) {
      n ? this.selecionarTodos() : this.limparSelecao()
    },
    cpfCnpj(n) {
      if (n.length > 14) {
        this.mask.cpfCnpj = {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          uppercase: true,
        }
      } else {
        this.mask.cpfCnpj = {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 3],
          uppercase: true,
        }
      }
    },
  },
  async created() {
    this.permissions = JSON.parse(localStorage.permissoes).clientes
    await Promise.all([
      // CLASSIFICAÇÃO Cliente
      axios.get('api/v1/info/classificacaocliente', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(dt => (
          this.classCliente.options.push({
            title: dt.nome,
            id: dt.id,
          })
        ))
      }),
      axios.get('api/v1/status_localizacao', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
      // eslint-disable-next-line array-callback-return
        res.data.dados.dados.map(dt => {
          this.localizacao.push({
            label: dt.nome,
            id: dt.id,
          })
        })
      }),
    ])
    this.allCliente()
  },
  methods: {
    async allCliente() {
      await axios.get('/api/v1/clientes/created', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        this.load = true
        this.totalRows = ''
        this.items = []

        if (localStorage.itemsStorage) {
          this.itemsStorage = JSON.parse(localStorage.getItem('itemsStorage'))
          this.items = this.itemsStorage
        } else {
          // eslint-disable-next-line array-callback-return
          res.data.dados.map(dt => {
            this.items.push(dt)
          })
        }
        this.totalRows = this.items.length
        this.load = false
      })
    },
    resetFiltro() {
      localStorage.removeItem('itemsStorage')
      this.$nextTick(() => {
        this.allCliente()
      })
    },
    limparSelecao() {
      this.select = []
    },
    selecionarTodos() {
      this.select = []
      this.items.map(dt => {
        this.select.push(dt)
      })
    },
    filtroClientes(formData) {
      this.load = true
      this.$bvModal.hide('modal-filtro')
      const url = formData === undefined ? '/api/v1/clientes/created' : '/api/v1/clientes/filtro_full'
      const method = formData === undefined ? 'GET' : 'POST'

      axios({ method, url, data: formData })
        .then(res => {
          this.items = []
          this.itemsStorage = []
          res.data.dados.map(dt => {
            this.items.push({
              id: dt.id,
              localizacao: dt.localizacao,
              status_cliente_id: dt.status_cliente_id,
              cliente: dt.cliente,
              nome: dt.nome,
              cpf_cnpj: dt.cpf_cnpj,
            })
          })
          this.itemsStorage = this.items
          this.totalRows = this.items.length
          this.limparSelecao()
          this.load = false
        }).catch(err => {
          if (err) {
            this.swalAlert('Error!!', 'Não encontrado!', 'error')
          }
        })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    swalAlert(...args) {
      const [title, text = null, icon = 'success', timer = 1500, confirmButton = 'primary', cancelButton = false, showCancelButton = false, confirmBtnText = null] = args

      const cancelButtonClass = cancelButton ? 'btn btn-outline-danger ml-1' : null
      const errorMessage = text !== null ? text : null
      const confirmButtonText = confirmBtnText !== null ? confirmBtnText : null

      this.$swal({
        title,
        text: errorMessage,
        icon,
        timer,
        showConfirmButton: false,
        confirmButtonText,
        showCancelButton,
        customClass: {
          confirmButton: `btn btn-${confirmButton}`,
          cancelButton: cancelButtonClass,
        },
        buttonsStyling: false,
      })
    },

    updateStatus() {
      this.select.forEach(value => {
        this.valor_enr.push(value.id)
      })
      const formData = {
        clientes_ids: this.valor_enr,
        localizacao: this.localizacaoCliente.value,
      }
      axios.put('api/v1/clientes/update_lote_localizacao/', formData, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        if (res.data) {
          this.localizado = ''
          this.filtroClientes()
          this.swalAlert('Localização atualizada!')
        }
      }).catch(error => {
        if (error.response) {
          this.swalAlert('ERRO', error.response.data.mensagem, 'error')
        }
      })
      this.select = []
      this.valor_enr = []
      this.localizacaoCliente = ''
      this.filtroClientes()
    },

    enriquecimento() {
      this.select.forEach(value => {
        this.valor_enr.push(value.cpf_cnpj)
      })
      const formData = {
        tipo: 'cpf',
        valor: this.valor_enr,
      }
      axios.post('api/v1/clientes/enriquecer/', formData, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        this.load = false
        if (res.data) {
          this.localizado = ''
          this.$swal({
            title: 'Enrriquecimento atualizada!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      }).catch(error => {
        this.load = false
        if (error.response) {
          this.$swal({
            title: 'ERRO',
            text: error.response.data.mensagem,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
      this.select = []
      this.valor_enr = []
    },

    updateLocalizacao() {
      this.select.forEach(value => {
        this.valor_enr.push(value.id)
      })
      const formData = {
        status_cliente_id: this.novaLocalizacao,
        clientes_ids: this.valor_enr,
      }
      axios.put('api/v1/clientes/update_lote_status_cliente_mult', formData, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          if (res.data) {
            this.novaLocalizacao = []
            this.filtroClientes()
            this.swalAlert('Classificação atualizada!')
          }
        })
        .catch(error => {
          if (error.response) {
            this.swalAlert('ERRO', error.response.data.mensagem, 'error')
          }
        })
      this.select = []
      this.valor_enr = []
    },

    consultaCEP() {
      const cep = this.cep.replace(/[^0-9]/g, '')
      axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        .then(res => {
          this.numero = ''
          this.rua = res.data.logradouro
          this.bairro = res.data.bairro
          this.cidade = res.data.localidade
          this.uf = res.data.uf
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      return valid
    },
    resetModal() {
      this.localizacaoCliente = null
      this.novaLocalizacao = null
      this.name = ''
      this.cpfCnpj = []
      this.cidades_id = ''
      this.cep = ''
      this.rua = ''
      this.endereco = ''
      this.numero = ''
      this.bairro = ''
      this.cidade = ''
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      const formData = {
        nome: this.name.toUpperCase(),
        cpf_cnpj: this.cpfCnpj.replace(/[^0-9]/g, ''),
        cidades_id: 1,
        endereco: this.rua,
        cep: this.cep.replace(/[^0-9]/g, ''),
      }
      axios.post('api/v1/clientes/store', formData, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.filtroClientes()
          this.swalAlert(res.data.mensagem)
          this.$refs['my-modal'].toggle('#toggle-btn')
        })
        .catch(error => {
          if (error.response) {
            this.$swal({
              title: 'Erro ao cadastrar',
              text: error.response.data.message,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
          } else {
            // Something happened in setting up the request that triggered an Error
          }
        })
    },
    randomBadge() {
      const badges = ['primary', 'success', '', 'danger', 'warning', 'info', 'dark']
      return badges[Math.floor(Math.random() * 6)]
    },
    async exportFile() {
      const file = await this.getDataToExported()
      if (file) {
        saveAs(file)
      }
    },
    async getDataToExported() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Relatório')
      const itemsPlan = this.items

      worksheet.columns = [
        { header: 'ID', key: 'id' },
        { header: 'NOME', key: 'nome' },
        { header: 'BAIRRO', key: 'bairro' },
        { header: 'CEP', key: 'cep' },
        { header: 'CIDADE', key: 'cidade' },
        { header: 'ID CIDADE', key: 'cidades_id' },
        { header: 'CODIGO', key: 'codigo' },
        { header: 'CPF/CNPJ', key: 'cpf_cnpj' },
        { header: 'DATA CRIAÇÃO', key: 'created_at' },
        { header: 'DATA CADASTRO CONFIRMADO', key: 'data_cadastro_confirmado' },
        { header: 'ENDEREÇO', key: 'endereco' },
        { header: 'LOCALIZAÇÃO', key: 'localizacao' },
        { header: 'NOME', key: 'nome' },
        { header: 'NÚMERO', key: 'numero' },
        { header: 'OBSERVAÇÕES', key: 'observacoes' },
        { header: 'PERFIL', key: 'perfil' },
        { header: 'ROTEIRO', key: 'roteiro' },
        { header: 'STATUS ID', key: 'status_cliente_id' },
        { header: 'STATUS LOCALIZAÇÃO', key: 'status_localizacao_id' },
        { header: 'UF', key: 'uf' },
        { header: 'ULTIMO CONTATO', key: 'ultimo_contato' },
        { header: 'DATA ATUALIZAÇÃO', key: 'update_at' },
      ]
      itemsPlan.map(item => {
        worksheet.addRow([
          item.id === null ? '' : item.id,
          item.nome === null ? '' : item.nome,
          item.bairro === null ? '' : item.bairro,
          item.cep === null ? '' : item.cep,
          item.cidade === null ? '' : item.cidade,
          item.cidades_id === null ? '' : item.cidades_id,
          item.codigo === null ? '' : item.codigo,
          item.cpf_cnpj === null ? '' : item.cpf_cnpj,
          item.created_at === null ? '' : this.dataHora(item.created_at),
          item.data_cadastro_confirmado === null ? '' : this.formatTimezone(item.data_cadastro_confirmado),
          item.endereco === null ? '' : item.endereco,
          item.localizacao === null ? '' : item.localizacao,
          item.nome === null ? '' : item.nome,
          item.numero === null ? '' : item.numero,
          item.observacoes === null ? '' : item.observacoes,
          item.perfil === null ? '' : item.perfil,
          item.roteiro === null ? '' : item.roteiro,
          item.status_cliente_id === null ? '' : item.status_cliente_id,
          item.status_localizacao_id === null ? '' : item.status_localizacao_id,
          item.uf === null ? '' : item.uf,
          item.ultimo_contato === null ? '' : this.formatTimezone(item.ultimo_contato),
          item.update_at === null ? '' : item.update_at,
        ])
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Relatório de Clientes', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
    setPlataforma(plataforma) {
      this.plataFormaSelected = plataforma
    },
    variantPlataforma(plataforma) {
      if (plataforma === this.plataFormaSelected) {
        return 'primary'
      }
      return 'outline-primary'
    },
    setEnriquecimento() {
      this.load = true
      if (this.select.length === 0) {
        this.load = false
        return this.$swal({
          title: 'Erro!',
          text: 'Nenhum cliente selecionado',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
      if (this.plataFormaSelected === 'TARGET') {
        this.enriquecimento()
      } else {
        this.shiftEnriquecimento()
      }
      return null
    },
    shiftEnriquecimento() {
      const cpfs = []
      this.select.forEach(value => {
        cpfs.push(value.cpf_cnpj)
      })
      const formData = {
        tipo: 'cpf',
        valor: cpfs,
      }
      axios.post('api/v1/clientes/enriquecer/shift', formData, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        this.load = false
        if (res.data) {
          this.localizado = ''
          this.swalAlert('Enriquecimento atualizada!')
        }
      }).catch(error => {
        this.load = false
        if (error.response) {
          this.swalAlert('ERRO', error.response.data.mensagem, 'error')
        }
      })
      this.select = []
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
